import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'image-loading-panel',
  templateUrl: './image-loading-panel.component.html',
  styleUrls: ['./image-loading-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageLoadingPanelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
