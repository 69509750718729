<ng-container *ngIf="shouldRenderItem">
    <ng-container [ngSwitch]="item.type">
        <ng-container *ngSwitchCase="'button'">
            <button type="button" mat-button [class.mat-icon-button]="toolbarMode === 'compact' || item.icon && !item.text"
                    (click)="executeOpenButtonAction(item.action)"
                    *ngIf="!item.dropdownItems; else dropdownButton">
                <mat-icon [svgIcon]="item.icon"></mat-icon>
                <span class="name" *ngIf="toolbarMode !== 'compact'" trans>{{item.text}}</span>
            </button>
            <ng-template #dropdownButton>
                <button type="button" mat-button [matMenuTriggerFor]="openMenu"
                        [class.mat-icon-button]="toolbarMode === 'compact' || item.icon && !item.text">
                    <mat-icon [svgIcon]="item.icon"></mat-icon>
                    <span class="name" *ngIf="toolbarMode !== 'compact'" trans>{{item.text}}</span>
                </button>
                <mat-menu #openMenu="matMenu" [overlapTrigger]="false">
                    <button type="button" mat-menu-item *ngFor="let actionConfig of item.dropdownItems"
                            (click)="executeOpenButtonAction(actionConfig.action)"
                            trans>{{actionConfig.label}}</button>
                </mat-menu>
            </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'zoomWidget'">
            <toolbar-zoom-widget></toolbar-zoom-widget>
        </ng-container>
        <toolbar-undo-widget *ngSwitchCase="'undoWidget'"></toolbar-undo-widget>
        <toolbar-panel-name-widget *ngSwitchCase="'panelNameWidget'"></toolbar-panel-name-widget>
        <img class="toolbar-image" [src]="item.src" alt="" *ngSwitchCase="'image'">
    </ng-container>
</ng-container>
